<template>
	<div>
		<div style="height: 100px;">
			<div v-for="(item,index) in listdata">
				<div style="margin-left: 10px;font-size: 18px;font-weight: 800;" class="py-2">{{item.title}}</div>
				<div style="column-count: 2;column-gap: 0;">
					<div v-for="(itema,indexa) in item.classlist">
						<div class="d-flex plugin-item" @click="tzym(itema.id)">
							<div>
								<img :src="itema.url" style="width: 30px;height: 30px;">
							</div>
							<div style="width: calc(100% - 30px);padding-left: 10px;">
								<div style="font-weight: 600;font-size: 12px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{itema.title}}</div>
								<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;color: #999;font-size: 10px;">{{itema.desc}}</div>
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		</div>
		
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		data() {
			return {
				listdata:[]
			}
		},
		// 页面初始化
		created(){
			this.getData()
		},
		methods: {
			getData() {
				this.axios.get('/admin/gpt/czgj',{
					token:true
				}).then(res=>{
					console.log(res.data.data)
					this.listdata = res.data.data
				})
			},
			
			tzym(e){
				this.$router.push({
					name: 'gpt_czgjgpt',
					params: {e}
				})
			}
		},
	}
</script>

<style scoped>
	.a-center{
		align-items: center;
	}
	.j-center{
		justify-content: center;
	}
	.plugin-item{
		padding: 10px 8px;
		background-color: #fff;
		border-radius: 6px;
		margin: 0 10px 8px 10px;
		display: flex;
		align-items: center;
		cursor: pointer;
		transition: 0.2s ease;
		box-shadow: 0 0px 10px #193e7b14;
	}
	.plugin-item:hover{
		transform: translateY(-3px);
		box-shadow:0 0 6px #999;
		transition:all 0.5s ease-out;
	}
</style>